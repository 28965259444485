import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero/Hero"
import About from "../components/About/About"
import Projects from "../components/Projects/Projects"
import Blogs from "../components/Blogs/Blogs"
import Contact from "../components/Contact/Contact"
import SEO from "../components/SEO"
import "../styles/global.scss"

export default ({ data }) => {
    // Projects
    const { projects: { nodes: projects }, } = data;
    // Blogs
    const { blogs: { nodes: blogs }, } = data;

    return (
        <Layout>
            <SEO />
            <Hero />
            <About />
            <Projects projects={projects} title="Projects" homePage />
            <Blogs blogs={blogs} title="Blogs" homePage />
            <Contact />
        </Layout>
    )
}

export const query = graphql`
    {
        projects: allMdx(filter: {fileAbsolutePath: {regex: "/content/projects/"}, frontmatter: {featured: {eq: true}}}, sort: {fields: frontmatter___id}) {
            nodes {
                frontmatter {
                    id
                    title
                    description
                    stack {
                        id
                        text
                        icon
                    }
                    github_link
                    hosted_link
                    image {
                        id
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        blogs: allMdx(filter: { fileAbsolutePath: { regex: "/content/blogs/" }, frontmatter: { featured: { eq: true } } }, sort: { fields: frontmatter___id }) {
            nodes {
                frontmatter {
                    featured
                    tags {
                        id
                        text
                    }
                    title
                    link
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`


