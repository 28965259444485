import React from "react"
import { IoMdArrowRoundForward } from "react-icons/io"
import SocialLinks from "../../constants/socialLinks"
import HeroSvg from "../../assets/hero.inline.svg"
import "./hero.scss"

const Hero = () => {
    return (
        <header className="hero">
            <div className="section section__center flex-container">
                <article className="hero__info">
                    <h3>Hello! I'm</h3>
                    <h1>Khusharth Patani</h1>
                    <h4>frontend engineer</h4>
                    <div className="u-underline"></div>
                    {/* <a
                        href="/resume.pdf"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        className="btn hero__btn"
                    >
                        Resume <IoMdArrowRoundForward />
                    </a> */}
                    <SocialLinks />
                </article>
                <HeroSvg className="hero__svg" />
            </div>
        </header>
    )
}

export default Hero
