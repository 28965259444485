import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Skills from "../../constants/skillsTabs"
import ExternalLink from "../ExternalLink"
import "./about.scss"

const query = graphql`
    {
        file(relativePath: { eq: "about.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const About = () => {
    const {
        file: {
            childImageSharp: { fluid },
        },
    } = useStaticQuery(query)

    return (
        <section
            className="section section__center  about u-bg-white"
            id="about"
        >
            <div className="flex-container">
                <div className="about__img-container">
                    <div className="about__img-div">
                        <Image fluid={fluid} className="about__img" />
                    </div>
                </div>
                <article className="about__info">
                    <h2>About me</h2>
                    <div className="u-underline"></div>
                    <p>
                        A frontend engineer trying to master{" "}
                        <ExternalLink href="https://reactjs.org/">
                            rasengan
                        </ExternalLink>{" "}
                        <ExternalLink href="https://www.smallcase.com/">
                            @smallcase
                        </ExternalLink>
                    </p>
                    <p>
                        I am a quick learner and always curious to learn about
                        new technologies. I enjoy the process of creating
                        beautiful websites from designing to deploying them in
                        the end.
                    </p>
                    <p>
                        Whenever I am not coding I spend my time{" "}
                        <ExternalLink href="https://www.instagram.com/khushu_zzz/">
                            drawing and sketching
                        </ExternalLink>{" "}
                        different anime characters.
                    </p>

                    <p>
                        <Skills />
                    </p>
                </article>
            </div>
        </section>
    )
}

export default About
