import React from "react"
// import PropTypes from "prop-types"
import Image from "gatsby-image"
import { FaGithub, FaShareSquare } from "react-icons/fa"
import "./project.scss"

import Html from "../../assets/icons/html5.inline.svg"
import Css from "../../assets/icons/css3.inline.svg"
import Sass from "../../assets/icons/sass.inline.svg"
// import Bootstrap from "../../assets/icons/bootstrap.inline.svg"
import Js from "../../assets/icons/javascript.inline.svg"
import Webpack from "../../assets/icons/webpack.inline.svg"
import ReactIcon from "../../assets/icons/react.inline.svg"
import ReduxIcon from "../../assets/icons/redux.inline.svg"
import StyledComponent from "../../assets/icons/styled-components.inline.svg"
import ChartJs from "../../assets/icons/chartjs.inline.svg"

const icons = {
    html5: <Html />,
    css: <Css />,
    sass: <Sass />,
    javascript: <Js />,
    webpack: <Webpack />,
    react: <ReactIcon />,
    redux: <ReduxIcon />,
    styledcomponents: <StyledComponent />,
    chartjs: <ChartJs />,
}

const Project = ({
    title,
    description,
    stack,
    image,
    github_link,
    hosted_link,
}) => {
    return (
        <article className="project">
            <div className="project__info">
                <Image
                    fluid={image.childImageSharp.fluid}
                    className="project__image"
                />
                <div>
                    <h3>{title}</h3>
                    <p className="project__description">{description}</p>
                    <div className="project__stack">
                        {stack.map(item => {
                            return (
                                <span key={item.id} className="project__skill">
                                    {icons[item.icon]} {item.text}
                                </span>
                            )
                        })}
                    </div>
                    <div className="project__links">
                        <a
                            href={github_link}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            className="btn"
                        >
                            <FaGithub /> Code
                        </a>
                        <a
                            href={hosted_link}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            className="btn"
                        >
                            <FaShareSquare /> Live demo
                        </a>
                    </div>
                </div>
            </div>
        </article>
    )
}

// Project.propTypes = {}

export default Project
