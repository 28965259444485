import React from "react"
import { Link } from "gatsby";
import Title from "../Title/Title"
import Project from "../Project/Project"

const Projects = ({ projects, title, homePage }) => {
    return (
        <>
            <section className="section projects u-bg-grey" id="projects">
                <Title title={title}></Title>
                <div className="section__center">
                    {projects.map(project => {
                        let proj = project.frontmatter
                        return <Project key={proj.id} {...proj} />
                    })}
                </div>
                <Link
                    to={homePage ? '/projects/' : "/"}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="btn btn__center"
                    style={homePage ? { marginBottom: 0 } : { marginBottom: '5rem' }}
                >
                    {homePage ? 'more projects' : "home"}
                </Link>

            </section>
        </>
    )
}

export default Projects
