import React from "react"
import "./ExternalLink.scss"

const ExternalLink = ({ children, href }) => {
    return (
        <a href={href} rel="noopener noreferrer" className="link underline">
            {children}
        </a>
    )
}

export default ExternalLink
