import React from "react"
import { FaLocationArrow } from "react-icons/fa"
import Title from "../Title/Title"
import "./contact.scss"

const Contact = () => {
    return (
        <section className="contact u-bg-grey" id="contact">
            <Title title="Get In Touch" />
            <div className="contact__form" style={{ marginBottom: '5rem' }}>
                <form className="form" action="https://formspree.io/xvowlgrz" method="POST">
                    <div className="form__group">
                        <input type="text" class="form__input" name="name" placeholder="Name" id="name" required />
                        <label for="name" class="form__label">Name</label>
                    </div>
                    <div className="form__group">
                        <input type="email" class="form__input" name="email" placeholder="Email" id="email" required />
                        <label for="email" class="form__label">Email</label>
                    </div>
                    <div className="form__group">
                        <textarea className="form__control form__message" name="message" id="message" placeholder="Message" rows="5" required></textarea>
                        <label for="message" class="form__label">Message</label>
                    </div>
                    <div className="form__group">
                        <button type="submit" className="btn form__btn--submit">Submit <FaLocationArrow /></button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contact
