import React from "react"
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <Helmet htmlAttributes={{ lang: "en" }} title="Khusharth Patani">
            <meta
                name="description"
                content="This is Khusharth's portfolio site"
            />
        </Helmet>
    )
}

export default SEO
