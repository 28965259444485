import React from "react"
import Html from "../assets/icons/html5.inline.svg"
import Css from "../assets/icons/css3.inline.svg"
import Sass from "../assets/icons/sass.inline.svg"
import Js from "../assets/icons/javascript.inline.svg"
import Webpack from "../assets/icons/webpack.inline.svg"
import ReactIcon from "../assets/icons/react.inline.svg"
import ReduxIcon from "../assets/icons/redux.inline.svg"
import Git from "../assets/icons/git.inline.svg"
import Linux from "../assets/icons/linux.inline.svg"
import NextJs from "../assets/icons/next.inline.svg"

const data = [
    {
        id: 1,
        text: "nextjs",
        icon: <NextJs />,
    },
    {
        id: 2,
        text: "React",
        icon: <ReactIcon />,
    },
    {
        id: 3,
        text: "javascript",
        icon: <Js />,
    },
    {
        id: 4,
        text: "Redux",
        icon: <ReduxIcon />,
    },
    {
        id: 5,
        text: "webpack",
        icon: <Webpack />,
    },
    {
        id: 6,
        text: "sass",
        icon: <Sass />,
    },
    {
        id: 7,
        text: "css3",
        icon: <Css />,
    },
    {
        id: 8,
        text: "html5",
        icon: <Html />,
    },
    {
        id: 9,
        text: "git",
        icon: <Git />,
    },
    {
        id: 10,
        text: "linux",
        icon: <Linux />,
    },
]

const skills = data.map(skill => {
    return (
        <span key={skill.id} className="about__skill">
            {skill.icon} {skill.text}
        </span>
    )
})

export default () => {
    return skills
}
